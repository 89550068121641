import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-card-badge',
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
  templateUrl: './card-badge.component.html',
  styleUrls: ['./card-badge.component.scss'],
})
export class CardBadgeComponent {
  @Input() color: string;
}
